import React from "react"
import SUILogo from "../images/Kinedu_Logo_White.png"

export default function Logo({ siteTitle }) {
  return (
    <h1 class="ui inverted header">
      <img src={SUILogo} alt="logo" id="logo" />
      <span>Documentation</span>
    </h1>
  )
}