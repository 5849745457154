import React from "react"
import Logo from "./logo"
import "./header.scss"

export default function Header() {
  return (
    <div className='headerContainer'>
      <div className='Col'>
        <Logo />
      </div>
    </div>
  )
}